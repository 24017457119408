import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      component: () => import("@/views/pages/LoginPage"),
    },
    {
      path: "/reset-password",
      component: () => import("@/views/pages/ResetPasswordPage"),
    },
    {
      path: "/",
      redirect: "/top",
      component: () => import("@/layouts/Layout"),
      children: [
        // Components
        {
          name: "Chat",
          path: "/chat",
          component: () => import("@/views/pages/ChatPage"),
        },
        {
          name: "Business",
          path: "/business",
          component: () => import("@/views/pages/BusinessPage"),
        },
        {
          name: "Storage",
          path: "/storage",
          component: () => import("@/views/pages/StoragePage"),
        },
        {
          name: "Tsuyukusa",
          path: "/tsuyukusa",
          component: () => import("@/views/pages/TsuyukusaPage"),
        },
        {
          name: "RoomReserve",
          path: "/room",
          component: () => import("@/views/pages/RoomPage"),
        },
        {
          name: "Event",
          path: "/event",
          component: () => import("@/views/pages/EventPage"),
        },
        {
          name: "Photo",
          path: "/photo",
          component: () => import("@/views/pages/PhotoPage"),
        },
        {
          name: "PhotoDetail",
          path: "/photo/detail",
          component: () => import("@/views/pages/PhotoDetailPage"),
        },
        {
          name: "Staff",
          path: "/staff",
          component: () => import("@/views/pages/StaffPage"),
        },
        {
          name: "Profile",
          path: "pages/profile",
          component: () => import("@/views/pages/Profile"),
        },

        {
          name: "Icons",
          path: "pages/icons",
          component: () => import("@/views/pages/Icons"),
        },

        {
          path: "/usrmng",
          component: () => import("@/views/pages/UsrmngPage"),
        },
        {
          name: "Top",
          path: "/top",
          component: () => import("@/views/pages/TopPage"),
        },
      ],
    },
    {
      path: "/*",
      redirect: "/top",
    },
  ],
});

export default router;

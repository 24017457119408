import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/base'
import animate from 'animate.css'
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.withCredentials = true
Vue.use(VueApexCharts)
Vue.use(VueAxios, axios)
Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    animate,
    render: h => h(App)
}).$mount('#app')
